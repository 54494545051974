//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cursor from "../../../assets/joobbook/cursor.png";
import reduce from "../../../assets/joobbook/reduce.png";
import add from "../../../assets/joobbook/add.png";
import backspace from "../../../assets/joobbook/backspace-solid.png";
export default {
  name: "SetJob",
  data() {
    return {
      date: this.$moment().format("yyyy.MM.DD"),
      name: "",
      code: "",
      cursor,
      reduce,
      add,
      backspace,
      gxshow: false,
      gxlist: [],
      selectedgx: [],
      //   toSelectGxList: []
      settedList: [],
      actionItem: {},
      actionDialog: false,
      actionIndex: null,
      active: 0,
      calctext: "",
      isoem: false,
      ifyvalue: "",
      casshow: false,
      cascaderValue: "",
      casoptions: [],
      casoemvalue: "",
      casRequestData: null,
      oemresult: {
        dept: "",
        userId: "",
      },
    };
  },
  activated() {
    this.$dd.ready(() => {
      this.$dd.biz.navigation.setTitle({
        title: "工序填报",
      });
    });
  },
  created() {
    this.$dd.ready(() => {
      this.$dd.biz.navigation.setTitle({
        title: "工序填报",
      });
    });
    this.name = this.$route.query.name;
    this.code = this.$route.query.code;
    this.getGxList();
  },
  mounted() {
    this.$dd.ready(() => {
      this.$dd.biz.navigation.setTitle({
        title: "工序填报",
      });
    });
  },
  methods: {
    getSettedList() {
      this.$toast.loading({ message: "加载中", forbidClick: true });
      this.axios
        .post("/mes/jobbook/getJobbookDataDay", {
          queryMap: {
            indate: this.date.split(".").join(""),
          },
        })
        .then((res) => {
          if (res && res.data && res.data.code && res.data.code === "SUCCESS") {
            const temp = res.data.data;
            temp.forEach((item) => {
              item.reportNum = Number(item.reportNum);
              this.gxlist.forEach((gx) => {
                if (
                  gx.operationId == item.operationId &&
                  gx.businessType == item.businessType
                ) {
                  this.$set(gx, "selected", true);
                }
              });
            });

            console.log(this.gxlist);
            this.settedList = temp;
            this.$toast.clear();
          } else {
            this.$toast.fail(res.data.msg);
          }
        });
    },
    getGxList() {
      this.axios
        .post("/mes/jobbook/getJobbookDataList", {
          queryMap: {},
        })
        .then((res) => {
          if (res && res.data && res.data.code && res.data.code === "SUCCESS") {
            this.gxlist = res.data.data;

            this.getSettedList();
          } else {
            this.$toast.fail(res.data.msg);
          }
        });
    },
    showDialog() {
      this.gxshow = true;
      this.selectedgx = [];
      this.$forceUpdate();
    },
    toggleDH(index) {
      this.$refs.DHcheckboxes[index].toggle();
    },
    toggleDZ(index) {
      this.$refs.DZcheckboxes[index].toggle();
    },
    confirmGX(action, done) {
      console.log("this.selectedgx", action);
      if (action === "confirm") {
        if (this.isoem && !this.oemresult.userId) {
          this.$toast.fail("请选择代工信息");
          done(false);
          return;
        }
        // if()
        let oemWorkshop = this.isoem ? this.oemresult.dept : "";
        let oemUserId =
          this.isoem && this.oemresult.userId != 0 ? this.oemresult.userId : "";
        for (let i = 0; i < this.selectedgx.length; i++) {
          for (let j = 0; j < this.gxlist.length; j++) {
            if (
              this.selectedgx[i] ==
              this.gxlist[j].operationId + this.gxlist[j].businessType
            ) {
              // this.gxlist[j].selected = true;
              this.$set(this.gxlist[j], "selected", true);
              let isContain = false;
              for (let index = 0; index < this.settedList.length; index++) {
                const setItem = this.settedList[index];
                if (
                  setItem.operationId == this.gxlist[j].operationId &&
                  setItem.businessType == this.gxlist[j].businessType &&
                  setItem.oemWorkshop == oemWorkshop &&
                  setItem.oemUserId == oemUserId
                ) {
                  isContain = true;
                }
              }
              if (!isContain) {
                this.settedList.unshift({
                  operationId: this.gxlist[j].operationId,
                  operationName: this.gxlist[j].operationName,
                  businessType: this.gxlist[j].businessType,
                  reportNum: 0,
                  oemUserId:oemUserId,
                  oemWorkshop: oemWorkshop,
                });
              }
            }
          }
        }
        done();
        console.log("this.settedList", this.settedList);
        console.log(this.gxlist);
      }else{
           done();
      }
    },

    reduceCount(item) {
      item.reportNum = item.reportNum > 0 ? item.reportNum - 1 : 0;
    },
    addCount(item) {
      if (item.reportNum > 9998) return;
      item.reportNum += 1;
    },
    showActionDialog(item, index) {
      if (item.dayApproveNum && item.dayApproveNum != "0")
        this.actionIndex = index;
      this.actionItem = JSON.parse(JSON.stringify(item));
      this.actionDialog = true;
    },
    // concatNum(num) {
    //   if ((this.actionItem.reportNum + "").length >= 4) return;
    //   this.actionItem.reportNum = Number(this.actionItem.reportNum + "" + num);
    // },
    concatNum(num) {
      if (this.calctext[0] != "+" && this.calctext[0] != "-") {
        this.$toast.fail("请先点击运算符");
        return;
      }
      this.contactCalctext(num);
    },
    concatAdd() {
      var lastStr = this.getLastString(this.calctext);
      if (lastStr == "+") {
        return;
      } else if (lastStr == "-") {
        this.doBackspace();
      }
      this.contactCalctext("+");
    },
    concatMius() {
      var lastStr = this.getLastString(this.calctext);
      if (lastStr == "-") {
        return;
      } else if (lastStr == "+") {
        this.doBackspace();
      }

      this.contactCalctext("-");
    },
    concatTotal() {
      var finalString =
        this.actionItem.reportNum == 0
          ? this.calctext
          : this.actionItem.reportNum + this.calctext;
      try {
        this.actionItem.reportNum = eval(finalString);
        this.calctext = "";
      } catch {
        this.$toast.fail("计算公式错误");
      }
    },
    contactCalctext(a) {
      this.calctext += a;
    },
    doBackspace() {
      if (this.calctext != "") {
        this.calctext = this.calctext.substring(0, this.calctext.length - 1);
      }
    },
    getLastString(text) {
      return text[text.length - 1];
    },
    // doBackspace() {
    //   this.actionItem.reportNum = Number(
    //     (this.actionItem.reportNum + "").slice(
    //       0,
    //       (this.actionItem.reportNum + "").length - 1
    //     )
    //   );
    // },
    confirmChange() {
      this.settedList.forEach((item, index) => {
        if (
          item.operationId == this.actionItem.operationId &&
          item.businessType == this.actionItem.businessType
        ) {
          this.$set(this.settedList, index, this.actionItem);
          console.log(this.settedList);
        }
      });
      // this.settedList[this.actionIndex] = this.actionItem;
      this.actionDialog = false;
    },

    submitJob() {
      if (this.settedList.length == 0) {
        this.$toast.fail("没有可提交数据");
        return;
      }
      this.$toast.loading({ message: "提交中", forbidClick: true });
      this.axios
        .post("/mes/jobbook/addJobbookData", this.settedList)
        .then((res) => {
          console.log("报工结果", res);
          if (res && res.data && res.data.code && res.data.code === "SUCCESS") {
            this.$toast.success("提交成功！");
            this.$router.back();
            // this.getSettedList();
          } else {
            this.$toast.fail(res.data.msg);
          }
        });
    },
    getFuncInfo() {
      this.axios
        .post("/mes/jobbook/findWorkshopUsers?site=1200", {})
        .then((res) => {
          if (res && res.data && res.data.code && res.data.code === "SUCCESS") {
            console.log(res);
            // this.getSettedList();
            let reustData = res.data.data;
            this.casRequestData = JSON.parse(JSON.stringify(reustData));
            this.setCasOption(reustData);
          } else {
            this.$toast.fail(res.data.msg);
          }
        });
    },
    setCasOption(reustData) {
      let casoptions = [];
      for (let i = 0; i < reustData.length; i++) {
        const item = reustData[i];
        casoptions.push({
          text: item.workshop,
          value: i,
          children: [{ text: "不指定", value: "0" }],
        });
        for (let j = 0; j < item.users.length; j++) {
          casoptions[i].children.push({
            text: item.users[j].userId + "-" + (item.users[j].userName || ""),
            value: item.users[j].userId,
          });
        }
      }
      this.casoptions = casoptions;
    },
    oemfunc(e) {
      if (!this.casRequestData) {
        this.getFuncInfo();
      }
      console.log(e);
    },
    onFinish({ selectedOptions }) {
      this.casshow = false;
      this.ifyvalue = selectedOptions.map((option) => option.text).join("/");
      console.log(selectedOptions);
      this.oemresult.dept = selectedOptions[0].text;
      this.oemresult.userId = selectedOptions[1].value;
    },
    onoemSearch() {
      let resultData = [];
      console.log();
      for (let index = 0; index < this.casRequestData.length; index++) {
        if (
          JSON.stringify(this.casRequestData[index]).indexOf(
            this.casoemvalue
          ) >= 0
        ) {
          resultData.push(this.casRequestData[index]);
        }
      }
      this.setCasOption(resultData);
    },
  },
  computed: {
    toSelectDHGxList() {
      return this.gxlist.filter((item) => {
        return item.businessType == "BULK";
      });
    },
    toSelectDZGxList() {
      return this.gxlist.filter((item) => {
        return item.businessType == "MTM";
      });
    },
    // DHsettedList() {
    //   return this.settedList.filter((item) => {
    //     return item.businessType == "BULK";
    //   });
    // },
    // DZsettedList() {
    //   return this.settedList.filter((item) => {
    //     return item.businessType == "MTM";
    //   });
    // },
  },
  watch: {
    settedList(newer, older) {
      console.log(older);
      this.DHsettedList = newer.filter((item) => {
        return item.businessType == "BULK";
      });

      this.DZsettedList = newer.filter((item) => {
        return item.businessType == "MTM";
      });
      console.log(this.DHsettedList, this.DZsettedList);
    },
  },
};
